<template>
  <div class="leftMenu">
    <img :src="imgUrl + storeOutImgUrl" alt="" class="leftMenu__img" />
    <div class="leftMenu__menu">
      <div v-for="item in routerLs" :key="item.key">
        <div
          v-if="item.meta.title !== '订单'"
          class="leftMenu__menu--item"
          @click="routerJump(item)"
        >
          <img :src="getRouterImg(item)" alt="" srcset="" />
          <span>{{ item.meta.title }}</span>
        </div>
        <el-badge :is-dot="newMsg" class="item" v-else>
          <div class="leftMenu__menu--item" @click="routerJump(item)">
            <img :src="getRouterImg(item)" alt="" srcset="" />
            <span>{{ item.meta.title }}</span>
          </div>
        </el-badge>
      </div>
    </div>
    <!-- 底部退出的开始 -->
    <div class="leftMenu__bottom" @click="loginOut">
      <img src="@/assets/loginOut.png" alt="" srcset="" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import routerLs from '@/router/permissionsRouter.js'
export default {
  props: {
    levelList: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      routerLs: routerLs.filter((item) => item.meta.show)
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo,
      newMsg: (store) => store.login.newMsg,
      permissions: (store) => store.permissions.permissions
    }),
    storeOutImgUrl () {
      return JSON.parse(this.shopInfo.businessImgUrls)[0]
    }
  },

  methods: {
    ...mapActions({
      goQuit: 'login/goQuit'
    }),
    routerJump (item) {
      if (this.$route.path === item.path) {
        return
      }
      this.$router.replace({
        path: item.path,
        query: {}
      })
    },
    getRouterImg (item) {
      console.log(this.levelList.meta)
      if (
        this.levelList.meta &&
        this.levelList.meta.title === item.meta.title
      ) {
        return item.meta.imgSrc
      }
      return item.meta.imgSrcNot
    },
    loginOut () {
      this.$confirm('是否确认退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.goQuit()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.leftMenu {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 22px 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  &__img {
    width: 121px;
    height: 121px;
    border-radius: 50%;
  }
  &__menu {
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    &--item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      cursor: pointer;
      margin-bottom: 62px;
      img {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
      }
      span {
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
  &__bottom {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0px;
    bottom: 42px;
    img {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }
}
</style>
