<template>
  <div class="layout">
    <div class="layout__left">
      <leftMenu :levelList='levelList'/>
    </div>
    <div class="layout__container">
      <div class="layout__container--head">
        <headMenu  @levelList='e=>levelList=e'/>
      </div>
      <div class="layout__container--body">
         <transition name="slide-left" mode="out-in">
         <router-view/>
         </transition>
      </div>
    </div>
  </div>
</template>

<script>
import leftMenu from '@/views/layout/components/leftMenu/index.vue'
import headMenu from '@/views/layout/components/headMenu/index.vue'
export default {
  components: {
    leftMenu,
    headMenu
  },
  data () {
    return {
      levelList: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  background-color: rgb(247,247,252);
  overflow: hidden;
  &__left {
    width: 200px;
    height: 100%;
    background: #543e3e;
    box-shadow: 8px 4px 37px 2px rgba(142, 142, 142, 0.21);
    border-radius: 24px;
  }
  &__container {
    margin-left: 40px;
    flex: 1;
    min-width: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &--head {
      height: 92px;
      background: #ffffff;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.03);
      border-radius: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 27px;
    }
    &--body{
      flex: 1;
      min-height: 0px;
    }
  }
}
.slide-left-enter {
    opacity: 0;
    -webkit-transform: translate(30px, 0);
    transform: translate(30px, 0);
  }
  .slide-left-enter-active{
    transition: all .3s ease;
  }
  .slide-left-leave-to{
    opacity: 0;
    -webkit-transform: translate(-30px, 0);
    transform: translate(-30px, 0);
  }
  .slide-left-leave-active {
    transition: all .3s ease;
  }
</style>
