<template>
  <div class="headMenu">
    <div class="headMenu__left">
      <div class="headMenu__left--back" v-if="levelList.length >= 2" @click="goBack">
        <img src="@/assets/goback.png" alt="" srcset="" />
        <span>返回</span>
      </div>
      <div class="headMenu__left--breadcrumb" v-if="levelList.length >= 2">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            :key="item.path"
            v-for="item in levelList"
            :to="{ path: item.path }"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <div class="headMenu__right">
      <div class="headMenu__right--input">
        <el-input
          placeholder="请输入关键词"
          suffix-icon="el-icon-search"
          v-model="keyword"
        >
        </el-input>
      </div>
        <i class="el-icon-bell"></i>
        <div>
          {{shopInfo.businessName}}
        </div>
      <div class="headMenu__right--dropdown">
        <el-dropdown @command='command'>
          <span class="el-dropdown-link">
            <img   :src="imgUrl + storeOutImgUrl" alt="" srcset="">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command='loginOut'>退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      keyword: '', // 关键词
      breadcrumbLs: [],
       levelList: []
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo,
      userInfo: (store) => store.login.userInfo
    }),
    storeOutImgUrl () {
      return JSON.parse(this.shopInfo.businessImgUrls)[0]
    }
  },
  watch: {
    $route: {
      handler () {
          this.getBreadcrumb()
      },
      immediate: true,
      deep: true
    },
    levelList: {
      handler () {
         this.$emit('levelList', this.levelList[0])
         console.log(this.levelList)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
     ...mapActions({
      goQuit: 'login/goQuit'
    }),
    goBack () {
       this.$router.back()
    },
    command (item) {
      if (item === 'loginOut') {
        this.$confirm('是否确认退出?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           this.goQuit()
        })
      }
    },
    getBreadcrumb () {
            // 1.获取当前的name,path,breadNumber
            var newName = this.$route.meta.title
            var newPath = this.$route.fullPath
            var newBreadNum = this.$route.meta.breadNumber

            // 2.获取前一页存下的name,path,breadNumber
            var routerInfo = JSON.parse(window.localStorage.getItem('routerInfo')) || []
            this.levelList = routerInfo

            // 3.判断是否是第一层，即初始位置，如果是第一层，分两种情况
            if (this.$route.meta.breadNumber === 1) {
                // 3.1 localStorage有值，那么this.levelList不是空值，说明是由上一个主路由进来的，需要清空之前localStorage的值，并且重新赋值给levelList
                if (this.levelList.length !== 0) {
                    localStorage.removeItem('routerInfo')
                    this.levelList = []
                }
                // 3.2 localStorage没有值，说明就是第一次进主路由，直接赋值
                this.levelList.push({ name: newName, path: newPath, breadNumber: newBreadNum, meta: this.$route.meta })
                // 按照数组格式的方式存放
                window.localStorage.setItem('routerInfo', JSON.stringify(this.levelList))
            } else {
            // 4.breadNumber不等于1时，那么this.levalList一定不是空值，判断获取到的这个breadNumber在数组中是否存在
                var isBreadNumber = false
                for (var i = 0; i < this.levelList.length; i++) {
                    if (this.levelList[i].breadNumber === newBreadNum) {
                        // return true; // 为true时表示数组内已存在 false表示不存在
                        isBreadNumber = true
                        break // 在这里不用return，return会终止循环
                    }
                }

                // 4.1 如果存在，说明是所有路由展示已经完（或者是展示了前面几个，然后点击面包屑），点击面包屑上其中的一个标签
                // 就要删掉这个后面的所有值，并且将点击所获得的的这个值存进this.levalList数组中
                if (isBreadNumber) {
                    // 删除点击的后面所有信息，localStorage中的数据也要删除（这里的删除是截取，而不是完全删除），然后将该信息添加进去
                    // 要注意的是，这里的变化还有可能是点击面包屑时产生的，其他地方通常是通过路由跳转得到的
                    // 注意这里splice的用法，如果直接写在localStorage.setItem 中，得到的值是被截取的值，而不是截取后的值
                    routerInfo.splice(newBreadNum, routerInfo.length - newBreadNum)
                    window.localStorage.setItem('routerInfo', JSON.stringify(routerInfo))
                } else {
                    // 4.2 如果不存在，说明是按照顺序进行的,如一级跳到二级，再从二级跳到三级
                    // 把它存到localStorage中，并把值给this.leavalList数组
                    this.levelList.push({ name: newName, path: newPath, breadNumber: newBreadNum, meta: this.$route.meta })
                    window.localStorage.setItem('routerInfo', JSON.stringify(this.levelList))
                }
            }
        }
  }
}
</script>
<style lang="scss" scoped>
.headMenu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 39px;
  padding: 0px 28px;
  box-sizing: border-box;
  &__left {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    &--back {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: 39px;
      }
      span {
        color: #543e3e;
        font-weight: 400;
        font-size: 16px;
        margin-left: 12px;
      }
    }
    &--breadcrumb {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 33px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    &--input {
      width: 307px;
      height: 68px;
      ::v-deep .el-input__inner {
        height: 68px !important;
        background: #f7f7fc;
        border-radius: 20px;
      }
    }
    .el-icon-bell {
      margin-left: 53px;
      font-size: 40px;
    }
    &--dropdown{
        margin-left: 53px;
        .el-dropdown-link{
          display: flex;
          align-items: center;
         img{
          width: 68px;
          height: 68px;
          border-radius: 20px;
          margin-right: 13px;
         }
        }
    }
  }
}
.headMenu__left--breadcrumb::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 39px;
  background-color: #000000;
  left: -17px;
  top: 0px;
}
/deep/ .el-icon-search{
  font-size: 20px !important;
}
.el-icon-bell{
  cursor: pointer;
}
</style>
